.login {
    display: grid;
    height: 100vh;
    place-items: center;
    text-align: center;
}

.header {
    color: $dark-blue;
    background: $background;
    font-weight: 700;
    font-size: 4rem;
    margin: 0;
    display: inline-block;
}

.header__wrapper {
    background: $blue-gradient;
    display: inline-block;
    margin-bottom: 2.5rem;
    padding-bottom: 0.4rem;
}

.welcome {
    color: rgba($dark-blue, 0.6);
    margin-bottom: 5rem;
}

.login__container {
    max-width: 325px;
}

.form__group {
    position: relative;

    input {
        background: transparent;
        border: none;
        display: block;
        margin-bottom: 4rem;
        padding: 5px;
        position: relative;
        transition: $trans;
        width: 100%;

        &:focus + label, &:valid + label {
            font-size: 1.2rem;
            opacity: 1;
            transform: translateY(-20px);
            span {
                display: none;
            }
        }

        &:focus + label::after, &:valid + label::after {
            bottom: -25px;
            width: 100%;
        }

        &:focus + label::before, &:valid + label::before {
            bottom: -25px;
            width: 0;
        }

        &:hover + label::before {
            background: red;
        }
    }

    label {
        bottom: 5px;
        left: 0;
        opacity: 0.5;
        pointer-events: none;
        position: absolute;
        text-align: left;
        transition: $trans;
        user-select: none;
        z-index: -2;
        width: 100%;

        &::before {
            background: lightgrey;
            content: '';
            height: 1.5px;
            left: 0;
            bottom: 0;
            opacity: 1;
            position: absolute;
            transition: $trans;
            width: 100%;
        }

        &::after {
            background: $blue-gradient;
            content: '';
            height: 1.5px;
            left: 0;
            bottom: 0;
            opacity: 1;
            position: absolute;
            transition: $trans;
            width: 0;
        }

        span {
            color: red;
        }
    }
}

.form__submit {
    border: none;
    border-radius: 1rem;
    background: $purple-gradient;
    color: white;
    cursor: pointer;
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    padding: 1.5rem;
    position: relative;
    text-transform: uppercase;
    transition: $trans;
    width: 90%;

    svg {
        position: absolute;
        left: 20%;
        top: 50%;
        transform: translateY(-50%);
    }

    &:hover {
        width: 100%;
    } 
}

.login-create {
    background: transparent;
    border: none;
    color: $blue;
    font-size: 1.5rem;

    &:hover {
        color: $accent;
        text-decoration: underline;
    }
}

.login__error {
    margin: 0;
    color: $accent;
    font-style: italic;

    &:last-of-type {
        margin-bottom: $l-size;
    }
}