* {
  box-sizing: border-box;
  outline: none;
}

html {
  font-size: 62.5%;
}

body {
  background: #ecf0f1;
  font-family: 'Montserrat', sans-serif;
  font-size: $m-size;
  line-height: 1.6;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

#app {
  width: 100%;
  height: 100%;
}