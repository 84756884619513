.account {
    position: relative;
}

.account__container {
    display: grid;
    height: 100vh;
    place-items: center;
}

.account__logout {
    position: absolute;
    top: 2%;
    right: 2%;
    z-index: 99;
}

.user__card {
    background: darken($background, 5%);
    border-radius: 2rem;
    display: grid;
    padding: $l-size;
    text-align: left;
    transform: scale(0.9);
    width: 290px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.5);

    @media (min-width: $desktop-breakpoint) {
        transform: scale(1);
        width: 350px;
    }
}

.user__subheading {
    color: rgba($dark-blue, 0.8);
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.img__container {
    display: grid;
    cursor: pointer;
    justify-self: center;
    height: 180px;
    position: relative;
    width: 180px;

    &:hover {
        .img__overlay {
            opacity: 1;
        }
    }
}

.img__overlay {
    background: rgba(black, 0.6);
    border-radius: 99%;
    display: grid;
    height: 100%;
    left: 0;
    position: absolute;
    opacity: 0;
    top: 0;
    transition: $trans;
    width: 100%;

    svg {
        color: white;
        font-size: 5rem;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.user__img {
    border-radius: 99%;
    cursor: pointer;
    justify-self: center;
    height: 180px;
    position: relative;
    transition: $trans;
    width: 180px;
}

.user__name {
    color: $dark-blue;
    text-align: center;
}

.user__text {
    border-bottom: 1px solid lightgrey;
    color: rgba($blue, 0.6);
    font-size: 1.8rem;
    margin: 0 0 $m-size 0;
    padding: 0;
    word-break: break-all;
}

.btn {
    background: $purple-gradient;
    border: none;
    border-radius: $s-size;
    color: white;
    display: inline-block;
    font-weight: 700;
    justify-self: right;
    padding: $xs-size $m-size;
    text-transform: uppercase;
    transition: $trans;

    &:hover {
        padding: $xs-size $l-size;
    }
}

.user__btns {
    display: flex;
    justify-content: space-between;
}

.user__delete {
    background: linear-gradient(127deg, rgba(195,34,34,1) 0%, rgba(240,80,123,1) 100%);
}

.user__check {
    background: linear-gradient(127deg, rgba(45,153,12,1) 0%, rgba(160,166,36,1) 100%);
}